import { Button, Label } from 'reactstrap';
import Icon from 'jsx/components/core/icons/Icon';
import moment from 'moment';
import { noop } from 'lodash';
import DirectDownload from 'jsx/components/core/form/components/DirectDownload';

import FormInputSelect from 'jsx/components/core/form/components/FormInputSelect';

const AdminWarehouseMetricsToolbar = (props) => {
  const {
    handleFilterChange = noop,
    onDownload = noop,
    onRefresh = noop,
    periods,
    properties,
    metrics,
    metricFilterControls = {},
    rows,
    filters
  } = props;

  metricFilterControls.period_id.options = periods?.rows?.map(period => ({id: period?.id, name: period?.description}));
  metricFilterControls.property_id.options = properties?.map(property => ({id: property?.id, name: property?.name}));
  metricFilterControls.metric_id.options = metrics?.map(metric => ({id: metric?.id, name: metric?.name}));

  const metricCount = `${rows} Metrics`;

  // Download file name
  const now = moment().format('YYYYMMDD-HHmmSS');
  const filename = `probe_metrics_${now}.csv`;

  // Disable download if no filters are set
  const filterCount = filters.metric_id.length + filters.period_id.length + filters.property_id.length;
  const downloadDisabled = (filterCount === 0);

  return (
    <div className="m-1 p-1 d-flex justify-content-end bg-light border border-gray-20 rounded">
      <Label className="m-0 mt-1 ml-2 mr-2 " style={{fontSize: 14}}>Filter</Label>
      <FormInputSelect selectFirst={false} className="mr-2" size="30px" handleChange={(event) => handleFilterChange(event, 'metric_id')} control={metricFilterControls.metric_id} />
      <FormInputSelect selectFirst={false} className="mr-2" size="30px" handleChange={(event) => handleFilterChange(event, 'period_id')} control={metricFilterControls.period_id} />
      <FormInputSelect selectFirst={false} className="mr-2" size="30px" handleChange={(event) => handleFilterChange(event, 'property_id')} control={metricFilterControls.property_id} />

      <Label className="m-0 mt-1 ml-2 mr-2" style={{fontSize: 14}}>Showing {metricCount}</Label>

      <Button onClick={onRefresh} size="sm" color="primary" className="mr-1">
        <Icon name="rotate" />
      </Button>
      <DirectDownload
        classNames="p-0"
        buttonType="success"
        handleDownload={(onProgressChange) => onDownload(onProgressChange)}
        caption="Download Filtered Metrics"
        filename={filename}
        showLabel={false}
        showIcon
        size="sm"
        disabled={downloadDisabled}
      />
    </div>
  );
};

export default AdminWarehouseMetricsToolbar;
